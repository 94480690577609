import React, { useState } from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import "./NavBarLinks.css";
import { navbarheaderlinks } from "../../../utils/constants";

function NavBarLinks({ isSideBar }) {
  const { t } = useTranslation();
  const handleClick = (id) => {
    if (id) {
      if (id.startsWith("https://")) {
        window.open(id, "_blank", "noopener,noreferrer");
      } else {
        const section = document.getElementById(id);
        if (section) {
          section.scrollIntoView({ behavior: "smooth", block: "start" });
          document.getElementById("btn_sideNavClose").click();
        }
      }
    }
  };

  return !isSideBar ? (
    <div className="d-flex align-items-center navbar-new-link">
      {navbarheaderlinks.map((dropdown) => (
        <div className="dropdown" key={dropdown.id}>
          {dropdown.title_link ? (
            <a
              className="link scroll"
              href={dropdown.title_link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.preventDefault();
                handleClick(dropdown.title_link);
              }}
            >
              {t(dropdown.title)}
              {dropdown.links && (
                <IoMdArrowDropdown
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                />
              )}
            </a>
          ) : (
            <Link
              className="link scroll"
              to="#"
              id={`${dropdown.title}Dropdown`}
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {t(dropdown.title)}
              {dropdown.links && (
                <IoMdArrowDropdown
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                />
              )}
            </Link>
          )}
          {dropdown.links && (
            <div
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby={`${dropdown.title}Dropdown`}
            >
              {dropdown.links.map((link, idx) => (
                <Link
                  className="dropdown-item"
                  to="#"
                  key={idx}
                  onClick={() => handleClick(link.link_id)}
                >
                  {t(link.link_title)}
                </Link>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  ) : (
    <div className="accordion navbar-mob-new-link" id="navbarAccordion">
      {navbarheaderlinks.map((dropdown) => (
        <div className="accordion-item" key={dropdown.id}>
          {dropdown.title_link ? (
            <a
              className="link scroll nav-link"
              href={dropdown.title_link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => {
                e.preventDefault();
                handleClick(dropdown.title_link);
              }}
            >
              {t(dropdown.title)}
            </a>
          ) : (
            <h2 className="accordion-header" id={`heading${dropdown.id}`}>
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#collapse${dropdown.id}`}
                aria-expanded="false"
                aria-controls={`collapse${dropdown.id}`}
              >
                {t(dropdown.title)}
              </button>
            </h2>
          )}

          {dropdown.links && (
            <div
              id={`collapse${dropdown.id}`}
              className="accordion-collapse collapse"
              aria-labelledby={`heading${dropdown.id}`}
              data-bs-parent="#navbarAccordion"
            >
              {dropdown.links.map((link, idx) => (
                <div className="accordion-body">
                  <Link
                    className=""
                    to="#"
                    key={idx}
                    onClick={() => handleClick(link.link_id)}
                  >
                    {t(link.link_title)}
                  </Link>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default NavBarLinks;
